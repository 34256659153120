<template>
  <div class="player-container">
    <div id="player" ref="player">
      <transition mode="out-in" name="fade" appear>
        <div v-if="playState < 3" class="curtain" style="background-color: white" :style="{ backgroundImage: 'url(' + thumb + ')' }">
          <div v-if="videoState < 3" class="video-container">
            <video class="splash-video"
                   ref="splashVideo"
                   :src="videoSrc"
                   :poster="thumb"
                   playsinline preload="auto" disablePictureInPicture controlsList="nodownload"
                   @pause="videoEnd()"
                   @error="videoEnd()"
                   @ended="videoEnd()"
                   @click="startVideo()"
            />
            <transition mode="out-in" name="fade" appear>
              <playCircle v-if="videoState < 2" class="icon play-icon" :size="60" @click="startVideo()" />
            </transition>
            <transition mode="out-in" name="fade" appear>
              <div v-if="videoState === 2 && visited" class="btn skip" @click="videoEnd()">Skip Video</div>
            </transition>
            <div v-if="!isModernBrowser && videoState < 2" class="browser-warning">
              Your browser is not currently supported. It may work but we recommend the latest Chrome or Edge for best performance.
            </div>
          </div>
          <transition mode="out-in" name="fade">
            <div v-if="videoState === 3 && playState < 3" class="loading">
              <Circle2 class="icon" background="#ffffff" />
              <p>Starting 3D experience</p>
            </div>
          </transition>
        </div>
      </transition>
    </div>
    <div v-if="!isSafariDesktop" class="fullscreen-icon" @click="toggleFullscreen()">
      <fullscreenIcon />
    </div>
  </div>
</template>

<script>
import { Player } from 'furioos-sdk';
import { isMobile, isSafari, isMobileSafari, isChrome, isFirefox, isEdge, browserVersion } from 'mobile-device-detect';
import playCircle from 'vue-material-design-icons/PlayCircle.vue';
import { Circle2 } from 'vue-loading-spinner';
import fullscreenIcon from 'vue-material-design-icons/Fullscreen.vue';

export default {
  name: 'Player',
  components: {
    playCircle,
    fullscreenIcon,
    Circle2
  },
  data () {
    return {
      player: undefined,
      playerOptions: {
        whiteLabel: true,
        hideToolbar: true,
        hideTitle: true,
        hidePlayButton: true
      },
      videoState: 0,
      playState: 0,
      fullscreen: false,
      visited: false
    }
  },
  mounted () {
    this.sendMessage({type: 'message', data: 'Initialising...'})
    this.player = new Player(this.gameId, 'player', this.playerOptions)
    this.player.onLoad(() => {
      this.sendMessage({type: 'message', data: 'Ready'})
      this.playState = 1
    })
    this.player.onStreamStart(() => {
      this.playState = 2
    })
    // receive message from game
    this.player.onSDKMessage(event => {
      if (JSON.parse(event).data === 'gameReady') {
        this.playState = 3
        this.sendMessage({type: 'message', data: 'Game activated'})
        isMobile ? this.player.sendSDKMessage('isMobile') : this.player.sendSDKMessage('isDesktop')
      } else {
        this.sendMessage(JSON.parse(event))
      }
    })
    // receive message from parent
    window.addEventListener('message', event => {
      this.handleMessageFromParent(event)
    })
  },
  computed: {
    gameId () {
      return this.$route.query.gameId
    },
    thumb () {
      return this.$route.query.thumb
    },
    videoSrc () {
      return this.$route.query.videosrc
    },
    isSafariDesktop () {
      return isSafari && !isMobileSafari
    },
    isModernBrowser () {
      return isChrome || isFirefox || isEdge && parseInt(browserVersion) > 85
    }
  },
  methods: {
    startPlayer () {
      this.player.start()
      // restart stream if none exists after 20 seconds
      setTimeout(() => { this.checkLoaded() }, 20000)
    },
    checkLoaded () { // keep asking for a connection until you get one
      if (this.playState < 2) {
        console.log('Retry start')
        this.player.restartStream()
      }
      setTimeout(() => {
        this.checkLoaded()
      }, 30000)
    },
    startVideo () {
      this.$refs.splashVideo.play()
      this.videoState = 2
      this.visited = localStorage.getItem('Visited')
      localStorage.setItem('Visited', true) // move to videoEnd
    },
    videoEnd () {
      this.videoState = 3
      if (this.player.loaded) {
        this.startPlayer()
      } else {
        setTimeout(() => {
          this.videoEnd()
        }, 1000);
      }
    },
    sendMessage (message) {
      window.parent.postMessage({
        xpanse_message_output: message
      }, '*')
    },
    handleMessageFromParent (event) {
      if (!event.data.xpanse_message_input) {
        return
      } else {
        this.player.sendSDKMessage(event.data.xpanse_message_input)
        console.log('sending ' + event)
      }
    },
    toggleFullscreen () {
      this.fullscreen = !this.fullscreen
      window.parent.postMessage({
        xpanse_message_output: {type: 'toggleFullscreen', data: isMobile}
      }, '*')
    }
  }
}
</script>

<style lang="scss">
.player-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  .block {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.5);
  }

  #player, iframe, .curtain {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-width: 0px;
    z-index: 999;
  }

  .curtain, .loading {
    background-color: rgba(0,0,0,0.5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 100;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;
    color: white;

    .icon {
      width: 60px;
      height: 60px;
      color: white;
      z-index: 999;
      font-size: 60px;
      line-height: 60px;

      .material-design-icon__svg {
        width: 60px;
        height: 60px;
        bottom: 0;
      }
    }

    .browser-warning {
      z-index: 99999;
      background: #FFD265;
      color: black;
      line-height: 1;
      padding: 10px 20px;
      border-radius: 5px;
      position: absolute;
      width: 70%;
      text-align: center;
      bottom: 10%;
      left: 50%;
      margin-left: -35%;
    }

    p {
      font-size: 1em;
      display: block;
      margin-top: 2em;
      user-select: none;
    }

    .splash-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .skip {
      position: absolute;
      bottom: 10px;
      right: 100px;
    }
  }

  .play-icon {
    cursor: pointer;
  }

  .fullscreen-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    color: white;
    z-index: 9999;
    cursor: pointer;
  }
}
</style>
